import React from "react";
import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import AuthScreen from "./app/pages/Auth/AuthScreen";
import ForgotPasswordScreen from "./app/pages/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./app/pages/Auth/ResetPassword/ResetPassword";
import Suport from "./app/pages/Suport/SuportScreen";
import TermoDeUso from "./app/pages/TermoDeUso/TermoDeUso";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={AuthScreen} />
        <Route exact path="/logout/:logout" component={AuthScreen} />
        <Route exact path="/forgot-password" component={ForgotPasswordScreen} />
        <Route
          exact
          path="/reset-password/:token/:email"
          component={ResetPassword}
        />
        <Route exact path="/esqueci-minha-senha" component={Suport} />
        <Route exact path="/termos-de-uso" component={TermoDeUso} />
      </Switch>
    </BrowserRouter>
  );
}
