import React from "react";
import { Button, Typography } from "../../components";
import Logo from "../../components/elements/Logo";

import * as S from "./SuportStyled";

const Suport = () => {
  return (
    <S.Container>
      <S.Header>
        <S.BoxBackLogin>
          <S.iconBack />
          <Typography variant="a" href="/">
            Voltar
          </Typography>
        </S.BoxBackLogin>
        <Logo />
        <div></div>
      </S.Header>
      <S.Iframe />
    </S.Container>
  );
};

export default Suport;
