import React, { useEffect, useRef, useState } from "react";
import Theme from "../../../styles/Theme";
import {
  Button,
  Input,
  Logo,
  PhotoProfileDefault,
  ProgessBar,
  Typography,
} from "../../components";
import { cleanStorage, getItem, setItem } from "../../services/storage.service";
import * as S from "./AuthStyled";
import { IconName, MdKeyboardArrowRight } from "react-icons/md";
import { api, apiUser } from "../../services/base.service";
import { useCookies } from "react-cookie";
import { setCookieAuth } from "./services/auth.service";
import * as Yup from "yup";
import Modal from "react-modal";

const customStyles = {
  content: {
    // width: "500px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const AuthScreen = (props) => {
  const formRef = useRef(null);
  const [errorAuth, setErrorAuth] = useState([]);
  console.log(process.env);
  // const main_domain = "conectedu.com.br";
  // const tutor_domain = "tutor.conectedu.com.br";
  // const main_url = "https://conectedu.com.br/";
  // const tutor_url = "https://tutor.conectedu.com.br/";
  // const hub_url = "https://hub.conectedu.com.br/";
  const main_domain = process.env.REACT_APP_MAIN_DOMAIN;
  const tutor_domain = process.env.REACT_APP_TUTOR_DOMAIN;
  const main_url = process.env.REACT_APP_MAIN_URL;
  const tutor_url = process.env.REACT_APP_TUTOR_URL;
  const hub_url = process.env.REACT_APP_HUB_URL;

  // const main_domain = "localhost";
  // const tutor_domain = "localhost";
  // const main_url = "http://localhost:3000/";
  // const tutor_url = "http://localhost:4202/";
  // const hub_url = "http://localhost:4200/";
  // const main_domain = "conectitatiaia.net";
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [loader, setLoader] = useState(false);
  console.log(props);
  useEffect(() => {
    console.log(cookies, "cookies");
    if (props.match.params.logout === "true") {
      cleanStorage();
      removeCookie("token", {
        path: "/",
        domain: main_domain,
      });
      removeCookie("name", {
        path: "/",
        domain: main_domain,
      });
      removeCookie("id", {
        path: "/",
        domain: main_domain,
      });
      window.location = main_url;
    }
    if (getItem("name")) {
      const tipoUsuario = getItem("tipoUsuario");
      // directUser(parseInt(tipoUsuario));
    }
  }, []);
  // if (props.match.params.logout === "true") {
  //   removeCookie("token", {
  //     path: "/",
  //     domain: main_domain,
  //   });
  //   removeCookie("name", {
  //     path: "/",
  //     domain: main_domain,
  //   });
  //   removeCookie("id", {
  //     path: "/",
  //     domain: main_domain,
  //   });
  //   cleanStorage();
  //   window.location = `https://${main_domain}/`;
  // }
  // // console.log(cookies.token, "cookies");
  const serivesMap = [];
  // const [user, setUser] = useState(undefined);
  // const headers = {
  //   "Accept-Language": "es-ES,es;q=0.8",
  //   "Content-Type": "application/x-www-form-urlencoded",
  //   Accept: "application/json",
  // };

  async function handleSubmit(data) {
    setErrorAuth([]);
    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string().required("Esse campo é obrigatório"),
        password: Yup.string().required("Esse campo é obrigatório"),
        // .min(6, "A senha deve conter no mínimo 6 caractéres")
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed
      console.log(data);
      postAuth(data);
    } catch (err) {
      console.log(err);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const postAuth = (data) => {
    setLoader(true);
    console.log(cookies);
    api
      .post("identidade/autenticar", {
        login: data.email,
        senha: data.password,
      })
      .then((res) => {
        console.log(res.data.data.usuarioToken.id);
        const config = {
          headers: { Authorization: `Bearer ${res.data.data.accessToken}` },
        };
        apiUser
          .get(`Usuario/${res.data.data.usuarioToken.id}`, config)
          .then((result) => {
            setCookie("token", res.data.data.accessToken, {
              path: "/",
              domain: main_domain,
            });
            setCookie("name", result.data.nome, {
              path: "/",
              domain: main_domain,
            });
            setCookie("id", res.data.data.usuarioToken.id, {
              path: "/",
              domain: main_domain,
            });

            setCookie("token", res.data.data.accessToken, {
              path: "/",
              domain: tutor_domain,
            });
            setCookie("name", result.data.nome, {
              path: "/",
              domain: tutor_domain,
            });
            setCookie("id", res.data.data.usuarioToken.id, {
              path: "/",
              domain: tutor_domain,
            });

            setItem("token", res.data.data.accessToken);
            setItem("name", result.data.nome);
            setItem("email", res.data.data.usuarioToken.email);
            setItem("tipoUsuario", result.data.tipoUsuario);
            // window.location.reload();
            directUser(result.data.tipoUsuario);
            setLoader(false);

            // setItem("email", result.data.nome);
            // setUser({ login: data.email, senha: data.password });
          });
        // window.location = `http://localhost:4200/#/${data.email}/${data.password}`;
        console.log(res);
      })
      .catch((error) => {
        setErrorAuth(["Usuário ou Senha incorretos"]);
        console.log(error);
        setLoader(false);
      });
  };

  const directUser = (typeUser) => {
    switch (typeUser) {
      case 2:
        window.location = hub_url;
        break;
      case 3:
        window.location = hub_url;
        break;
      case 4:
        window.location = tutor_url;
        break;
      case 5:
        setErrorAuth(["Usuário ou Senha incorretos"]);
        break;
      case 6:
        window.location = tutor_url;
        break;
      case 7:
        window.location = hub_url;
        break;
      default:
        return;
    }
  };

  const logout = () => {
    cleanStorage();
    // removeCookie("token");
    // removeCookie("name");
    // removeCookie("email");
    // removeCookie("id");
    window.location.reload();
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <S.Container>
      <S.ContainerLeft>
        <S.ContainerRight>
          <img src={process.env.REACT_APP_LOGO_SECRETARY} />
        </S.ContainerRight>
        {!isUserLogged ? (
          <S.ContainerAuth>
            <Logo />
            <S.FormContainer ref={formRef} onSubmit={handleSubmit}>
              <S.BoxText>
                {/* <S.Title>Fazer login</S.Title> */}
                <Typography variant="h2">Fazer login</Typography>
                {/* <Typography variant="body1"> */}
                {/* Conecte-se para visualizar os serviços
                </Typography> */}
              </S.BoxText>
              {/* <span>Fazer login</span> */}
              <Input label="E-mail/N• de Matrícula" name="email" type="text" />
              <S.InputPassword>
                <Input label="Senha" name="password" type="password" />
              </S.InputPassword>
              {errorAuth.length > 0 && (
                <S.MensageError>- {errorAuth[0]}</S.MensageError>
              )}
              <Button loader={loader} onClick={logout}>
                Login
              </Button>
              {/* <ProgessBar /> */}
              <S.TextPassword variant="a" href="/forgot-password">
                Esqueceu sua senha?
              </S.TextPassword>
              <S.FooterAuth>
                <S.TextNotAcess variant="a" href="/esqueci-minha-senha">
                  Não consegue realizar o acesso ?
                </S.TextNotAcess>
                •
                <S.TextTermsOfUse
                  target="_blank"
                  variant="a"
                  href="/termos-de-uso"
                >
                  Termo de uso
                </S.TextTermsOfUse>
              </S.FooterAuth>{" "}
              {/* <S.ManualDoAluno onClick={openModal}>
                Clique aqui para ter acesso ao manual
              </S.ManualDoAluno> */}
            </S.FormContainer>
          </S.ContainerAuth>
        ) : (
          <S.UserServices>
            <S.ContentAuth>
              {/* <Logo /> */}
              <Logo marginBottom={50} marginTop={30} />

              <S.UserInfo>
                <PhotoProfileDefault name={getItem("name")} />
                <S.TextEmail>{getItem("email")}</S.TextEmail>
                <Typography variant="a" onClick={logout}>
                  sair
                </Typography>
              </S.UserInfo>
              <h3
                style={{
                  fontSize: 20,
                  fontWeight: 400,
                  marginBottom: 10,
                  marginTop: 15,
                }}
              >
                Seus acessos:
              </h3>
              <S.Services>
                {serivesMap.map((service, key) => {
                  return (
                    <S.CardService key={key}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <S.IconDirect />
                        <S.ContentCard>
                          <Typography
                            variant="h3"
                            marginBottom={"10px"}
                            color={Theme.palette.text.black}
                          >
                            {service.nome}
                          </Typography>
                          <Typography
                            variant="body2"
                            color={Theme.palette.grey[500]}
                          >
                            {service.nameUrl}
                          </Typography>
                        </S.ContentCard>
                      </div>
                      <S.IconCard className="IconCard" />
                    </S.CardService>
                  );
                })}
              </S.Services>
            </S.ContentAuth>
            {/* <span>ltoemasdasd</span> */}
          </S.UserServices>
        )}

        {/* <S.UserServices></S.UserServices> */}
      </S.ContainerLeft>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          <S.ButtonCloseModal onClick={closeModal}>Fechar</S.ButtonCloseModal>
          <S.ContainerManuais>
            <Logo marginBottom={20} marginTop={30} />
            <S.ButtonManual>
              <S.ManualDoAluno
                target="_blank"
                href="https://condominioappstorage.blob.core.windows.net/educameriti/Tutorial%20Aluno%20%20S%C3%A3o%20jo%C3%A3o.pdf"
                download
              >
                Clique aqui para acessar o manual do aluno.
              </S.ManualDoAluno>
            </S.ButtonManual>
            <S.ButtonManual>
              <S.ManualDoAluno
                target="_blank"
                href="https://condominioappstorage.blob.core.windows.net/educameriti/Tutorial%20professor%20S%C3%A3o%20jo%C3%A3o.pdf"
              >
                Clique aqui para acessar o manual do Professor.
              </S.ManualDoAluno>
            </S.ButtonManual>
            <S.ButtonManual>
              <S.ManualDoAluno
                target="_blank"
                href="https://condominioappstorage.blob.core.windows.net/educameriti/Tutorial_Gest%C3%A3o_%20Sao%20Joao.pdf"
              >
                Clique aqui para acessar o manual do Gestor.
              </S.ManualDoAluno>
            </S.ButtonManual>
            {/* <Button></Button> */}
            {/* <div>I am a modal</div> */}
          </S.ContainerManuais>
        </Modal>
      </div>
    </S.Container>
  );
};

export default AuthScreen;
