import React, { useEffect, useState } from "react";
import { MdArrowBack, MdCheck } from "react-icons/md";
import { Button, Input, Logo, Typography } from "../../../components";
import { api } from "../../../services/base.service";

import * as S from "./ForgotPasswordStyled";

const ForgotPasswordScreen = () => {
  const [emailSent, setSentEmail] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {}, []);

  function handleSubmit(data) {
    setLoader(true);
    console.log(data);
    api
      .post("identidade/esqueceu-senha", {
        email: data.email,
      })
      .then(
        (res) => {
          setSentEmail(true);
          setLoader(false);
          console.log(res);
        },
        (error) => {
          setLoader(false);
        }
      );
  }

  return (
    <S.Container>
      <S.ContainerLeft>
        <S.ContainerAuth>
          <S.BoxBackLogin>
            <MdArrowBack />
            <Typography variant="a" href="/">
              Voltar
            </Typography>
          </S.BoxBackLogin>
          {!emailSent ? (
            <div>
              <Logo marginBottom={20} marginTop={30} />
              <S.FormContainer onSubmit={handleSubmit}>
                <S.BoxText>
                  <Typography variant="h2">Esqueci minha senha</Typography>
                </S.BoxText>
                <Input
                  label="E-mail"
                  name="email"
                  type="email"
                  placeholder={"user@example.com"}
                />
                <Button loader={loader} onClick={handleSubmit}>
                  Redefinir senha
                </Button>
              </S.FormContainer>
            </div>
          ) : (
            <S.SentEmail>
              <S.IconSentEmail />
              <Typography variant="h2">
                E-mail enviado! Verifique a sua caixa de entrada
              </Typography>
            </S.SentEmail>
          )}
        </S.ContainerAuth>
      </S.ContainerLeft>
    </S.Container>
  );
};

export default ForgotPasswordScreen;
