import { ThemeProvider } from "styled-components";
import "./App.css";
import AuthScreen from "./app/pages/Auth/AuthScreen";
import Routes from "./Router";
import GlobalStyles from "./styles/GlobalStyles";
import Theme from "./styles/Theme";
function App() {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyles />
      <Routes />
      {/* <AuthScreen></AuthScreen> */}
    </ThemeProvider>
  );
}

export default App;
