import React, { useEffect, useState } from "react";
import { Logo } from "../../components";
import { api } from "../../services/base.service";
import { getLGPD } from "./service/TermoDeUso.service";

import * as S from "./TermoDeUsoStyled";
const project = "Educa Meriti";
const ldpdText = [
  {
    title: "SERVIÇOS",
    subTitles: [
      {
        description: `O presente Termo de Uso trata sobre ${project} criada com a finalidade de ajudar alunos e professores a organizar tarefas, aumentar a colaboração e promover uma melhor comunicação, tornando o ensino mais produtivo, colaborativo e significativo. Trata-se de uma ferramenta simplificada e fácil de usar que ajuda os gestores, pedagogos e professores a gerenciar as aulas. Com ${project}, os educadores podem compartilhar conteúdos, tarefas, realizar aulas remotas, avaliar alunos, acompanhar a presença, enviar feedback e compartilhar materiais didáticos digitais, tais como: documentos, fotos e vídeos.`,
      },
      {
        description: `${project} se compromete a fornecer os Serviços de acordo com o presente Termo de Uso.`,
      },
      {
        description: `${project} pode, a seu exclusivo critério, descontinuar os Serviços ou modificar os recursos dos Serviços de tempos em tempos sem aviso prévio. A utilização dos Serviços requer um ou mais dispositivos compatíveis, acesso à Internet (taxas podem ser aplicadas) e determinados softwares (taxas podem ser aplicadas) e podem exigir a obtenção de atualizações de tempos em tempos. Como a utilização dos Serviços envolve hardware, software e acesso à Internet, a capacidade de utilizar os Serviços pode ser afetada pelo desempenho desses fatores, alheios ao ${project}. O usuário reconhece e concorda que tais requisitos de sistema, que podem ser alterados de tempos em tempos, são se sua responsabilidade.`,
      },
    ],
  },
  {
    title: "RESPONSABILIDADE PELAS INFORMAÇÕES E COMUNICAÇÕES DO USUÁRIO",
    subTitles: [
      {
        description: `O usuário pode ser solicitado a fornecer informações pessoais para registro e/ou utilização de determinados Serviços. O usuário concorda que qualquer informação desse tipo deve ser precisa e verdadeira. Também podendo ser solicitado a escolher um nome de usuário e senha. O usuário é inteiramente responsável pela manutenção da segurança do nome de usuário e senha e concorda em não divulgá-los a terceiros.`,
      },
      {
        description: `O usuário manifestamente concorda ser o único responsável pelo conteúdo por ele veiculado ao utilizar os Serviços, incluindo, mas não se restringindo a upload de arquivos, comunicações orais, escritas e gestuais, transmissão de conteúdo e afins.`,
      },
      {
        description: `O usuário concorda que não usará os Serviços de forma que viole a legislação aplicável, incluindo, mas não limitado a leis e regulamentos relativos a propriedade intelectual, anti-spam, privacidade e antiterrorismo e leis que exijam o consentimento dos sujeitos de gravações de áudio e vídeo, senso o único responsável pela conformidade com todas os direitos e todos os regulamentos. O usuário também concorda em não utilizar os Serviços para comunicar qualquer mensagem ou material que seja perturbador, difamatório, ameaçador, obsceno, indecente, que viole os direitos de propriedade intelectual de qualquer parte ou seja ilegal, que daria origem à responsabilidade civil ou que constitua ou encoraje uma conduta que possa constituir uma infração penal, nos termos de qualquer lei ou regulamento aplicável.`,
      },
    ],
    title: "RESPONSABILIDADE PELAS INFORMAÇÕES E COMUNICAÇÕES DO USUÁRIO",
    subTitles: [
      {
        description: `O usuário pode ser solicitado a fornecer informações pessoais para registro e/ou utilização de determinados Serviços. O usuário concorda que qualquer informação desse tipo deve ser precisa e verdadeira. Também podendo ser solicitado a escolher um nome de usuário e senha. O usuário é inteiramente responsável pela manutenção da segurança do nome de usuário e senha e concorda em não divulgá-los a terceiros.`,
      },
      {
        description: `O usuário manifestamente concorda ser o único responsável pelo conteúdo por ele veiculado ao utilizar os Serviços, incluindo, mas não se restringindo a upload de arquivos, comunicações orais, escritas e gestuais, transmissão de conteúdo e afins.`,
      },
      {
        description: `O usuário concorda que não usará os Serviços de forma que viole a legislação aplicável, incluindo, mas não limitado a leis e regulamentos relativos a propriedade intelectual, anti-spam, privacidade e antiterrorismo e leis que exijam o consentimento dos sujeitos de gravações de áudio e vídeo, senso o único responsável pela conformidade com todas os direitos e todos os regulamentos. O usuário também concorda em não utilizar os Serviços para comunicar qualquer mensagem ou material que seja perturbador, difamatório, ameaçador, obsceno, indecente, que viole os direitos de propriedade intelectual de qualquer parte ou seja ilegal, que daria origem à responsabilidade civil ou que constitua ou encoraje uma conduta que possa constituir uma infração penal, nos termos de qualquer lei ou regulamento aplicável. `,
      },
      {
        description: `O usuário ainda concorda em não efetuar upload ou transmitir qualquer software, conteúdo ou código que faça ou se destine a prejudicar, desativar, destruir ou afetar adversamente o desempenho dos Serviços de qualquer forma ou que prejudique ou tenha a intenção de prejudicar ou extrair informações ou dados de outros hardwares, softwares ou redes da ${project} ou outros usuários de Serviços.`,
      },
      {
        description: `${project} reserva-se o direito de investigar e tomar as medidas apropriadas contra qualquer usuário que, a critério exclusivo da ${project}, seja suspeito de violar as disposições supra. `,
      },
      {
        description: `Embora ${project} não seja responsável por nenhum conteúdo que viole estas disposições, ${project} poderá excluir qualquer conteúdo a qualquer momento, sem aviso prévio. `,
      },
      {
        description:
          "O usuário detém os direitos autorais e quaisquer outros direitos que já possua do conteúdo que envia, publica ou exibe por meio dos serviços.",
      },
    ],
  },
  {
    title: "RESPONSABILIDADE POR CONTEÚDO DOS DEMAIS USUÁRIOS",
    subTitles: [
      {
        description: `É possível que outros usuários dos Serviços possam violar uma ou mais das proibições acima, da mesma forma, ${project} não assume qualquer responsabilidade pelas violações. Caso o usuário tome conhecimento de qualquer violação deste contrato em relação à utilização dos serviços por outrem, pedimos entrar em contato pelo endereço de e-mail conectedusuporte@gmail.com. ${project} pode investigar quaisquer queixas e violações que cheguem à sua atenção e pode tomar quaisquer medidas que julgar apropriadas, no entanto, também se reserva o direito de não tomar nenhuma ação, caso adequado assim seja.`,
      },
    ],
  },
  {
    title: "ELEGIBILIDADE",
    subTitles: [
      {
        description:
          "O usuário deverá declarar, nos termos da cláusula 2.1, sua idade para análise de elegibilidade de utilização da plataforma. Sendo o usuário maior de idade e elegível, seu registro se completará de forma automática. Os usuário que sejam usuários menores de idade no momento do cadastro ficarão sujeitos à apresentação do formulário “Declaração de Anuência do Responsável Legal” como exigido pela Lei de Proteção à Privacidade On-line das Crianças para acesso à plataforma.",
      },
    ],
  },
  {
    title: "ENCARGOS",
    subTitles: [
      {
        description:
          "Todos Serviços prestados de acordo com o presente Termo de Uso se darão gratuitamente para o Usuário.",
      },
    ],
  },
  {
    title: "LIMITAÇÕES DE UTILIZAÇÃO",
    subTitles: [
      {
        description:
          "O usuário se compromete a não reproduzir, revender ou distribuir os serviços ou quaisquer relatórios ou dados por ele gerados para qualquer finalidade, salvo se especificamente autorizado a fazê-lo.",
      },
      {
        description:
          "Da mesma forma, se compromete a não se envolver em nenhuma atividade ou utilizar a plataforma de qualquer maneira que possa danificar, desativar, sobrecarregar, prejudicar ou interferir ou interromper os serviços, ou quaisquer servidores ou redes conectadas aos Serviços ou de seus sistemas de segurança.",
      },
    ],
  },
  {
    title: "DIREITOS DO PROPRIETÁRIO.",
    subTitles: [
      {
        description:
          "A TECHDOG SOLUÇÕES DE INTERNET LTDA., sociedade empresária limitada,constituída e registrada na Junta Comercial do Estado do Rio de Janeiro (JUCERJA) sob o NIRE 33.2.1001476-5, por despacho em 28/07/2015, inscrita no CNPJ/MF sob o nº 22.941.743/0001-07, com sede na Avenida Jose Silva De Azevedo Neto, nº 200, Bloco 006, Loja 0115, Barra da Tijuca, Rio de Janeiro, RJ – CEP: 22.775-056, detêm a propriedade de todos os direitos de propriedade sobre os Serviços e todos os nomes comerciais, marcas registradas, marcas de serviço, logotipos e nomes de domínio associados ou exibidos com os Serviços.",
      },
    ],
  },
  {
    title: "DIREITOS AUTORAIS",
    subTitles: [
      {
        description: `${project} usa e trata os dados que coleta para oferecer, fornecer, personalizar, manter e melhorar seus produtos e serviços. Isso inclui usar os dados para: (i) criar e atualizar sua conta; (ii) confirmar sua identidade e demais dados fornecidos; (iii) facilitar o oferecimento dos serviços. Também inclui o processamento automático dos dados para o recebimento de ofertas e oportunidades; (iv) processar ou intermediar a relação com os parceiros; (v) realizar operações internas necessárias para que possam ser prestados serviços por meio da ${project}, inclusive para solucionar problemas de software e operacionais, conduzir análise de dados, testes e pesquisas, e monitorar e analisar tendências de uso e atividade.
        `,
      },
      {
        description: `O usuário é o único proprietário dos dados pessoais por ele informados na ${project} .`,
      },
      {
        description: `O tratamento dos dados previstos nestes TERMOS DE USO é condição para o fornecimento do serviço da ${project}, sendo, portanto, realizados nos termos da legislação de dados.`,
      },
      {
        description: `${project} nunca tratará ou divulgará seus dados pessoais para qualquer uso ou finalidade diversa dos necessários para a plataforma. `,
      },
      {
        description: `${project}, entretanto, poderá tratar dados pessoais e/ou fornecê-los aos parceiros quando o usuário manifestar interesse na aquisição de bens e serviços por eles oferecidos.`,
      },
      {
        description: `O exercício dos direitos do titular dos dados, conforme previsto na legislação de proteção de dados poderá ser realizado por comunicação enviada por e-mail para o endereço: conectedusuporte@gmail.com.`,
      },
      {
        description: `${project} poderá compartilhar dados se houver um requisito legal e/ou regulatório, ordem judicial ou de órgão ou autoridade competente, sendo que nestes casos só revelará às autoridades os dados pessoais fornecidos em sua exata dimensão, sem divulgar outros não formalmente requeridos. `,
      },
      {
        description: `Fica ${project} obrigada a proteger os dados coletados e tratados contra destruição ou perda acidental, alteração, divulgação ou acessos não autorizados. Em caso de perda ou alteração de Dados, ${project}  procederá a sua restauração, quando possível, sob suas próprias expensas.`,
      },
      {
        description: `${project} deverá dar conhecimento ao usuário sobre a natureza e o alcance de eventuais descumprimentos das obrigações de segurança acima mencionadas, devendo auxiliá-lo com todos os meios necessários, às suas expensas, na implementação de ações que permitam remediar ou responder às violações, inclusive, se for o caso, por meio de notificações para autoridades competentes e às pessoas envolvidas em tal violação.`,
      },
      {
        description: `${project} deverá garantir a utilização de métodos de processamento dos dados (hospedagem e manutenção, entre outros) compatíveis com a legislação brasileira.`,
      },
      {
        description: `As informações pessoais serão retidas durante o período em que o usuário estiver vinculado ao ${project}, possuindo conta de acesso ativa, a menos que um período de retenção mais longo seja requerido ou permitido por lei. ${project}  se reserva o direito de guardar todos os registros dos serviços realizados por meio do aplicativo pelo prazo de 5 (cinco) anos.`,
      },
      {
        description: `O tratamento dos dados pessoais será realizado de acordo com as disposições estabelecidas neste Aviso de Privacidade, enquanto vigorar a relação entre o usuário e ${project}  e por mais 5 (cinco) anos, que podem ser prorrogados se houver um requisito legal e/ou regulatório para fazê-lo, para qualquer outro objetivo estabelecido pela Lei nº 13.709/2018 - Lei Geral de Proteção de Dados (“LGPD”) e para defender os interesses da em processos judiciais, administrativos e arbitrais.`,
      },
    ],
  },
  {
    title: "RESCISÃO",
    subTitles: [
      {
        description: `Caso o usuário, em qualquer momento, não estiver satisfeito com os serviços, sua única solução será deixar de utilizar os Serviços. Para tal, o website da ${project} contém informações sobre como encerrar sua conta.`,
      },
    ],
  },
  {
    title: "NENHUMA GARANTIA",
    subTitles: [
      {
        description: `O USUÁRIO DECLARA QUE ENTENDE E CONCORDA QUE OS SERVIÇOS SÃO FORNECIDOS "NO ESTADO EM QUE SE ENCONTRAM" E ${project} REJEITA TODAS AS GARANTIAS DE QUALQUER TIPO, EXPRESSAS OU IMPLÍCITAS, INCLUINDO, SEM LIMITAÇÃO, QUALQUER GARANTIA DE COMERCIALIZAÇÃO, APTIDÃO PARA UM PROPÓSITO ESPECÍFICO OU DE NÃO VIOLAÇÃO. ${project} NÃO FORNECE GARANTIA OU REPRESENTAÇÃO SOBRE OS RESULTADOS QUE PODEM SER OBTIDOS DO USO DOS SERVIÇOS, RELATIVOS À PRECISÃO OU CONFIABILIDADE DE QUALQUER INFORMAÇÃO OBTIDA PELO SERVIÇO OU DE QUE OS SERVIÇOS CUMPRIRÃO QUALQUER REQUISITO DO USUÁRIO OU SEJA ININTERRUPTO, OPORTUNO, SEGURO OU LIVRE DE ERROS. A UTILIZAÇÃO DOS SERVIÇOS É POR SEU ÚNICO RISCO. QUALQUER MATERIAL E/OU DADOS DESCARREGADOS OU DE OUTRA FORMA OBTIDOS PELA UTILIZAÇÃO DOS SERVIÇOS ESTÃO POR SEU PRÓPRIO CRITÉRIO E RISCO. VOCÊ SERÁ UNICAMENTE RESPONSÁVEL POR QUALQUER DANO QUE POSSA SER RESULTANTE DA UTILIZAÇÃO DOS SERVIÇOS. TODO O RISCO DECORRENTE DA UTILIZAÇÃO OU DESEMPENHO DOS SERVIÇOS PERMANECE COM VOCÊ. ${project} NÃO ASSUME QUALQUER RESPONSABILIDADE PELA RETENÇÃO DE QUALQUER INFORMAÇÃO DE USUÁRIO OU COMUNICAÇÃO ENTRE USUÁRIOS. `,
      },
      {
        description: `O usuário concorda em indenizar, defender e isentar ${project} e seus executivos, diretores, funcionários, consultores, agentes, fornecedores de todas e quaisquer reclamações de terceiros, responsabilidade, danos e/ou custos (incluindo, mas não limitado a, honorários advocatícios) decorrentes da utilização dos serviços, da violação deste Termo ou da violação ou infração pelo detentor ou por qualquer outro usuário de sua conta, de qualquer propriedade intelectual ou outro direito de qualquer pessoa ou entidade.`,
      },
    ],
  },
  {
    title: "PRIVACIDADE",
    subTitles: [
      {
        description: `${project} se compromete a empregar do mais amplo e diligente dever de privacidade, declarando expressamente que não cederá, a nenhum título, qualquer informação obtida direta ou indiretamente de seus usuários pelo uso da plataforma.`,
      },
      {
        description: `${project}, para a melhor execução dos serviços coleta e armazena alguns dados além dos diretamente informados como: (a) Informações técnicas sobre dispositivos, rede e conexão à Internet; (b) Informações sobre método de utilização da plataforma; (c) Duração, participantes e horário das aulas e sessões.`,
      },
      {
        description: `Nenhuma das aulas e sessões e seu conteúdo será gravada pela ${project}, respeitando a privacidade de todos os participantes.`,
      },
    ],
  },
  {
    title: "LIMITAÇÃO DE RESPONSABILIDADE",
    subTitles: [
      {
        description: `NA EXTENSÃO MÁXIMA PERMITIDA PELA LEI APLICÁVEL, EM NENHUM CASO ${project} SERÁ RESPONSÁVEL POR QUAISQUER DANOS ESPECIAIS, INCIDENTAIS, INDIRETOS, EXEMPLARES OU CONSEQUENCIAIS (INCLUINDO, SEM LIMITAÇÃO, DANOS POR PERDA DE LUCROS EMPRESARIAIS, INTERRUPÇÃO DE NEGÓCIOS, PERDA DE INFORMAÇÕES EMPRESARIAIS OU QUALQUER OUTRA PERDA PECUNIÁRIA OU DANOS) RESULTANTES DA UTILIZAÇÃO OU DA INCAPACIDADE DE UTILIZAR OS SERVIÇOS OU A PROVISÃO DESSES OU FALHA DE FORNECER SERVIÇOS TÉCNICOS OU OUTROS SERVIÇOS DE APOIO, SEJA ADVINDOS DE LITÍGIO (INCLUINDO NEGLIGÊNCIA) OU QUALQUER OUTRA TEORIA LEGAL, MESMO QUE AVISADOS SOBRE A POSSIBILIDADE DE TAIS DANOS.`,
      },
    ],
  },
  {
    title: "DISPOSIÇÕES GERAIS",
    subTitles: [
      {
        description: `Este Contrato será regido pelo direito brasileiro.`,
      },
      {
        description: `As Partes elegem o foro da Comarca do Rio de Janeiro, Estado do Rio de Janeiro, para dirimir quaisquer questões oriundas deste Termo de Uso.`,
      },
      {
        description: `O presente Termo de uso fica sujeito a alterações futuras independentemente de prévia notificação. Em hipótese de sua alteração, será informado o usuário, ou seu Responsável Legal - nos termos do item 4.1 -, importando a continuidade do acesso à plataforma em sua anuência integral aos novos termos.`,
      },
    ],
  },
];
const TermoDeUso = () => {
  const [lgpd, setLgpd] = useState("");
  useEffect(() => {
    getLGPD().then((res) => {
      setLgpd(res.data);
    });
  }, []);
  return (
    <S.Container>
      <S.Header>
        <S.Title>Termo de uso</S.Title>
      </S.Header>
      <S.ContainerTextTermo>
        <S.TitleLGPD style={{ textAlign: "center" }}>TERMO DE USO</S.TitleLGPD>
        <S.SubTitleLGPD>
          IMPORTANTE, LEIA COM ATENÇÃO: A UTILIZAÇÃO E O ACESSO À PLATAFORMA E
          SERVIÇOS DO CONECTEDU DE PROPRIEDADE DA TECHDOG SOLUÇÕES DE INTERNET
          LTDA.”, sociedade empresária limitada,constituída e registrada na
          Junta Comercial do Estado do Rio de Janeiro (JUCERJA) sob o NIRE
          33.2.1001476-5, por despacho em 28/07/2015, inscrita no CNPJ/MF sob o
          nº 22.941.743/0001-07, com sede na Avenida Jose Silva De Azevedo Neto,
          nº 200, Bloco 006, Loja 0115, Barra da Tijuca, Rio de Janeiro, RJ –
          CEP: 22.775-056 ("CONECTEDU") ESTÃO CONDICIONADOS AO CUMPRIMENTO E À
          ACEITAÇÃO DESTES TERMOS.
          <br /> AO ACESSAR O SITE A PLATAFORMA {project}
           OU UTILIZANDO SEUS SERVIÇOS, VOCÊ CONCORDA EM CUMPRIR ESSES TERMOS E
          CONDIÇÕES.
        </S.SubTitleLGPD>
        {ldpdText.map((lgpd, index) => {
          return (
            <>
              <S.TitleLGPD>
                {index + 1}. {lgpd.title}
              </S.TitleLGPD>
              {lgpd.subTitles.map((lgpdSub, indexLgpdSub) => {
                return (
                  <>
                    <S.SubTitleLGPD>
                      {indexLgpdSub + 1}. {lgpdSub.description}
                    </S.SubTitleLGPD>
                  </>
                );
              })}
            </>
          );
        })}
      </S.ContainerTextTermo>
    </S.Container>
  );
};

export default TermoDeUso;
